import cns from "classnames";
import { useRouter } from "next/router";

import Breadcrumbs, { BreadcrumbsLinks } from "@Components/Breadcrumbs";
import Container from "@Components/Container";
import ButtonIcon from "@Components/ui/ButtonIcon";

import styles from "./index.module.scss";

type PageTemplateProps = React.PropsWithChildren & {
    className?: string;
    buttonBack?: boolean;
    breadcrumbs?: BreadcrumbsLinks;
};

const PageTemplate: React.FC<PageTemplateProps> = ({
    className,
    breadcrumbs,
    buttonBack = true,
    children,
}) => {
    const router = useRouter();

    return (
        <main className={cns(className, styles.component)}>
            <Container>
                {(breadcrumbs || buttonBack) && (
                    <header className={styles.component__header}>
                        {buttonBack && (
                            <ButtonIcon
                                className={styles.component__buttonBack}
                                icon="angle-left"
                                aria-label="Вернуться назад"
                                onClick={() => router.back()}
                            />
                        )}

                        {breadcrumbs && (
                            <Breadcrumbs
                                className={styles.component__breadcrumbs}
                                links={breadcrumbs}
                            />
                        )}
                    </header>
                )}

                {children}
            </Container>
        </main>
    );
};

export default PageTemplate;
