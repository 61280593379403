import cns from "classnames";
import { useRouter } from "next/router";

import pages from "@Settings/pages";
import PageTemplate from "@Components/PageTemplate";
import Button from "@Components/ui/Button";
import Image from "@Components/ui/Image";

import imageCircle from "./images/circle.svg";
import styles from "./index.module.scss";

type PageProps = {
    is404?: boolean;
};

const Page: React.FC<PageProps> = ({ is404 = true }) => {
    const router = useRouter();

    const handleButtonClick = () => router.back();

    return (
        <PageTemplate buttonBack={false} className={styles.component}>
            <div
                className={cns(styles.component__container, "text text_center")}
            >
                <Image
                    className={styles.component__image}
                    src={imageCircle}
                    alt=""
                />

                <h1
                    className={cns(
                        styles.component__title,
                        "text text_h1 text_uppercase"
                    )}
                >
                    {is404 ? "404" : "Ой!"}
                </h1>

                <h2
                    className={cns(
                        styles.component__subtitle,
                        "text text_h3 text_uppercase"
                    )}
                >
                    {is404 ? "Страница не найдена" : "Возникла какая-то ошибка"}
                </h2>

                <p className={cns(styles.component__text, "text text_p3")}>
                    {is404
                        ? "Извините, мы не смогли найти страницу, которую вы ищете. Код ошибки: 404"
                        : "Извините, возникла какая-то ошибка, которая не зависит от нас. Пожалуйста, повторите ваше действие. А если опять возникнет ошибка, свяжитесь с поддержкой"}
                </p>

                <Button
                    className={styles.component__button}
                    href={is404 ? pages.main.link : undefined}
                    size="md"
                    onClick={is404 ? undefined : handleButtonClick}
                >
                    {is404 ? "Вернуться на главную" : "Вернуться назад"}
                </Button>
            </div>
        </PageTemplate>
    );
};

export default Page;
