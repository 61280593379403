import cns from "classnames";
import React from "react";

import Link from "@Components/ui/Link";
import SvgIcon from "@Components/ui/SvgIcon";

import styles from "./index.module.scss";

export type BreadcrumbsLinks = ({ link?: string; text: string } | undefined)[];

export type BreadcrumbsProps = {
    className?: string;
    links: BreadcrumbsLinks;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ className, links }) => {
    if (!links.length) {
        return null;
    }

    return (
        <div className={cns(className, styles.component, "text text_p5")}>
            {links.map((item, itemIndex) =>
                typeof item === "object" ? (
                    <React.Fragment key={itemIndex}>
                        <div className={styles.component__item}>
                            {item?.link ? (
                                <Link href={item?.link}>{item?.text}</Link>
                            ) : (
                                <>{item?.text}</>
                            )}
                        </div>

                        {itemIndex !== links.length - 1 && (
                            <SvgIcon
                                className={styles.component__divider}
                                icon="angle-right"
                            />
                        )}
                    </React.Fragment>
                ) : null
            )}
        </div>
    );
};

export default Breadcrumbs;
