import type { NextPageWithLayout } from "@Types/common";

import { REVALIDATE_IN_SECONDS } from "@Settings";
import Seo from "@Components/Seo";
import { useGetPageDataPage } from "@Hooks/api/page";
import PageError404 from "@Pages/error-404";
import { withCommonProps } from "@Utils/datalayer";

// export const runtime = 'edge';

export const getStaticProps = withCommonProps(async (ctx, client) => {
    const page = await client.pages.getPage("error");

    return {
        props: {
            data: { page },
        },
        revalidate: REVALIDATE_IN_SECONDS,
    };
});

const Page: NextPageWithLayout = () => {
    const page = useGetPageDataPage();

    return (
        <>
            <Seo pageSeo={page?.seo} />
            <PageError404 />
        </>
    );
};

export default Page;
